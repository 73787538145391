import React, {
    useCallback,
    useContext,
    useMemo,
    useReducer,
    Dispatch,
    createContext,
} from 'react'

import { getLinkToken } from './firebase'

import { PlaidLinkError } from 'react-plaid-link'
import { debugPrint } from '../utils/helpers'

interface LinkToken {
    [key: string]: string
}

interface LinkState {
    byUser: LinkToken
    byItem: LinkToken
    error: PlaidLinkError
    linkErrorMessage?: string
}

const initialState = {
    byUser: {}, // normal case
    byItem: {}, // update mode
    error: {},
}

type LinkAction =
    | {
        type: 'LINK_TOKEN_CREATED'
        id: string
        token: string
    }
    | {
        type: 'LINK_TOKEN_UPDATE_MODE_CREATED'
        id: string
        token: string
    }
    | {
        type: 'LINK_TOKEN_ERROR'
        error: Error
    }
    | {
        type: 'DELETE_USER_LINK_TOKEN'
        id: string
    }
    | {
        type: 'DELETE_ITEM_LINK_TOKEN'
        id: number
    }

interface LinkContextShape extends LinkState {
    dispatch: Dispatch<LinkAction>
    generateLinkToken: (userId: string, itemId: number | null | undefined) => Promise<void>
    deleteLinkToken: (userId: string | null, itemId: number | null) => void
    linkTokens: LinkState
}

const LinkContext = createContext<LinkContextShape>(initialState as LinkContextShape)

export function LinkProvider(props: { children: React.ReactNode }) {
    const [linkTokens, dispatch] = useReducer(reducer, initialState)

    const generateLinkToken = useCallback(async (userId: string, itemId: string | null) => {
        // if itemId is not null, update mode is triggered
        try {
            const link_token = await getLinkToken(itemId)
            if (link_token) {
                if (itemId !== null)
                    dispatch({ type: 'LINK_TOKEN_UPDATE_MODE_CREATED', id: itemId, token: link_token })
                else
                    dispatch({ type: 'LINK_TOKEN_CREATED', id: userId, token: link_token })
            }
        } catch (error) {
            debugPrint(`getLinkToken failed with error: ${JSON.stringify(error)}`, 'error')
            dispatch({ type: 'LINK_TOKEN_ERROR', error: error as Error })
        }
    }, [])

    const deleteLinkToken = useCallback(async (userId: string, itemId: number) => {
        if (userId != null) {
            dispatch({ type: 'DELETE_USER_LINK_TOKEN', id: userId, })
        } else {
            dispatch({ type: 'DELETE_ITEM_LINK_TOKEN', id: itemId, })
        }
    }, [])

    const value = useMemo(
        () => ({
            linkTokens,
            deleteLinkToken,
            generateLinkToken,
        }),
        [linkTokens, generateLinkToken, deleteLinkToken]
    )

    //@ts-ignore
    return <LinkContext.Provider value={value} {...props} />
}

/**
 * @desc Handles updates to the LinkTokens state as dictated by dispatched actions.
 */
function reducer(state: any, action: LinkAction) {
    switch (action.type) {
        case 'LINK_TOKEN_CREATED':
            return {
                ...state,
                byUser: {
                    [action.id]: action.token,
                },
                error: {},
            }

        case 'LINK_TOKEN_UPDATE_MODE_CREATED':
            return {
                ...state,
                error: {},
                byItem: {
                    ...state.byItem,
                    [action.id]: action.token,
                },
            }
        case 'DELETE_USER_LINK_TOKEN':
            return {
                ...state,
                byUser: {
                    [action.id]: '',
                },
            }
        case 'DELETE_ITEM_LINK_TOKEN':
            return {
                ...state,
                byItem: {
                    ...state.byItem,
                    [action.id]: '',
                },
            }
        case 'LINK_TOKEN_ERROR':
            return {
                ...state,
                error: action.error,
                linkErrorMessage: "Failed to generate link token. Please contact support if the issue persists.",
            }
        default:
            debugPrint(`Unknown action: ${JSON.stringify(action)}`, 'warn')
            return state
    }
}

/**
 * @desc A convenience hook to provide access to the Link context state in components.
 */
export default function useLink() {
    const context = useContext(LinkContext)
    if (!context)
        throw new Error(`useLink must be used within a LinkProvider`)

    return context
}
