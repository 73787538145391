import { FirebaseApp, initializeApp, } from 'firebase/app'
import { getDatabase, DatabaseReference, ref, onValue, off } from "firebase/database"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions"
import { UserDataType } from '../types'
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link'
import { v4 as uuid } from 'uuid'
import { debugPrint } from '../utils/helpers'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
}

let firebaseInstance: FirebaseApp

function getFirebase(): (FirebaseApp | null) {
    if (typeof window === "undefined") return null
    if (firebaseInstance) return firebaseInstance
    firebaseInstance = initializeApp(firebaseConfig)
    if (process.env.REACT_APP_PROJECT_ID !== 'parlay-p-sports-newsletter')
        console.info('👉 INIT FIREBASE', process.env.REACT_APP_PROJECT_ID)
    return firebaseInstance
}


function getUserPlaidDataDBRef(uid: string): DatabaseReference {
    const app = getFirebase()
    if (app) {
        const db = getDatabase(app)
        if (db) return ref(db, `users-meta/plaid-data/${uid}`)
    }

    throw new Error("Firebase instance or Firebase Database NOT found.")
}


function observeUserData(uid: string, onUpdate: (data: UserDataType) => void): () => void {
    const dbRef = getUserPlaidDataDBRef(uid)
    onValue(dbRef, (snapshot) => {
        if (!snapshot.exists())
            onUpdate({ accounts: {}, orders: {}, hasLoaded: true })
        else {
            const dataSnap = snapshot.val()
            const data: UserDataType = {
                accounts: dataSnap.accounts ?? {},
                defaultAccount: dataSnap.defaultAccount ?? '',
                email: dataSnap.email,
                hasLoaded: true,
                legalName: dataSnap.legalName ?? '',
                orders: dataSnap.orders ?? {},
            }
            onUpdate(data)
        }
    }, (error) => {
        let errorMessage: string = error.message
        if ('code' in error && error.code === 'permission_denied')
            errorMessage = 'System error: permission denied. Plese contact support.'
    })
    return () => off(dbRef)
}


async function checkInUserAndSetupAccount({ uid, email }: { uid: string, email?: string }): Promise<void> {
    const app = getFirebase()
    if (app) {
        const functions = getFunctions(app)
        if (window.location.hostname === 'localhost')
            connectFunctionsEmulator(functions, 'localhost', 5001)
        const callableCheckInUserAndSetupAccount = httpsCallable(functions, 'checkInAndSetupAccount')
        try {
            const response = await callableCheckInUserAndSetupAccount({ uid, email })
            debugPrint(`checkInUserAndSetupAccount succeeded with response: ${JSON.stringify(response)}`, 'info')
        } catch (error) {
            debugPrint(`checkInUserAndSetupAccount failed with error: ${JSON.stringify(error)}`, 'error')
        }
    }
}


async function createOrder({ name, amount }: { name: string, amount: string }): Promise<string> {
    const app = getFirebase()
    if (app) {
        const functions = getFunctions(app)
        if (window.location.hostname === 'localhost')
            connectFunctionsEmulator(functions, 'localhost', 5001)
        const callableCreateOrder = httpsCallable(functions, 'createOrder')
        try {
            const timestamp = Date.now()
            const orderId = uuid()
            const response = await callableCreateOrder({ name, amount, orderId, timestamp })
            debugPrint(`createOrder succeeded with response: ${JSON.stringify(response)}`, 'info')
            return orderId
        } catch (error) {
            debugPrint(`createOrder failed with error: ${JSON.stringify(error)}`, 'error')
            throw error
        }
    } else
        throw new Error("Firebase instance or Firebase Functions NOT found.")
}


async function setDefaultAccount(accountId: string): Promise<void> {
    const app = getFirebase()
    if (app) {
        const functions = getFunctions(app)
        if (window.location.hostname === 'localhost')
            connectFunctionsEmulator(functions, 'localhost', 5001)
        const callableSetDefaultAccount = httpsCallable(functions, 'setDefaultAccount')
        try {
            const response = await callableSetDefaultAccount({ accountId })
            debugPrint(`setDefaultAccount succeeded with response: ${JSON.stringify(response)}`, 'info')
        } catch (error) {
            debugPrint(`setDefaultAccount failed with error: ${JSON.stringify(error)}`, 'error')
            throw error
        }
    } else
        throw new Error("Firebase instance or Firebase Functions NOT found.")
}

async function removeAccount(accountId: string): Promise<void> {
    const app = getFirebase()
    if (app) {
        const functions = getFunctions(app)
        if (window.location.hostname === 'localhost')
            connectFunctionsEmulator(functions, 'localhost', 5001)
        const callableRemoveAccount = httpsCallable(functions, 'removeAccount')
        try {
            const response = await callableRemoveAccount({ accountId })
            debugPrint(`removeAccount succeeded with response: ${JSON.stringify(response)}`, 'info')
        } catch (error) {
            debugPrint(`removeAccount failed with error: ${JSON.stringify(error)}`, 'error')
            throw error
        }
    } else
        throw new Error("Firebase instance or Firebase Functions NOT found.")
}


async function setLegalNameAndEmail(legalName: string, email?: string): Promise<void> {
    const app = getFirebase()
    if (app) {
        const functions = getFunctions(app)
        if (window.location.hostname === 'localhost')
            connectFunctionsEmulator(functions, 'localhost', 5001)
        const callableSetLegalNameAndEmail = httpsCallable(functions, 'setLegalNameAndEmail')
        try {
            const response = await callableSetLegalNameAndEmail({ legalName, email })
            debugPrint(`setLegalNameAndEmail succeeded with response: ${JSON.stringify(response)}`, 'info')
        } catch (error) {
            debugPrint(`setLegalNameAndEmail failed with error: ${JSON.stringify(error)}`, 'error')
            throw error
        }
    } else
        throw new Error("Firebase instance or Firebase Functions NOT found.")
}


async function setEmail(email: string): Promise<void> {
    const app = getFirebase()
    if (app) {
        const functions = getFunctions(app)
        if (window.location.hostname === 'localhost')
            connectFunctionsEmulator(functions, 'localhost', 5001)
        const callableSetEmail = httpsCallable(functions, 'setEmail')
        try {
            const response = await callableSetEmail({ email })
            debugPrint(`setEmail succeeded with response: ${JSON.stringify(response)}`, 'info')
        } catch (error) {
            debugPrint(`setEmail failed with error: ${JSON.stringify(error)}`, 'error')
            throw error
        }
    } else
        throw new Error("Firebase instance or Firebase Functions NOT found.")
}


async function getLinkToken(itemID: string | null): Promise<string> {
    const app = getFirebase()
    if (app) {
        const functions = getFunctions(app)
        if (window.location.hostname === 'localhost')
            connectFunctionsEmulator(functions, 'localhost', 5001)
        const callableCreateLinkToken = httpsCallable(functions, 'generateLinkToken')
        try {
            const response: any = await callableCreateLinkToken()
            debugPrint(`getLinkToken succeeded with response: ${JSON.stringify(response)}`, 'info')
            return response.data.link_token
        } catch (error) {
            debugPrint(`getLinkToken failed with error: ${JSON.stringify(error)}`, 'error')
            throw error
        }
    } else
        throw new Error("Firebase instance or Firebase Functions NOT found.")
}


async function updateItemState(itemId: number, state: string): Promise<void> {
    return Promise.reject("NOT IMPLEMENTED")
}


async function exchangeToken(
    publicToken: string,
    institution: any,
    accounts: PlaidLinkOnSuccessMetadata['accounts'],
    userId: string
) {
    const app = getFirebase()
    if (app) {
        const functions = getFunctions(app)
        if (window.location.hostname === 'localhost')
            connectFunctionsEmulator(functions, 'localhost', 5001)
        const callableExchangePublicToken = httpsCallable(functions, 'exchangePublicToken')
        try {
            const data = {
                publicToken,
                institutionId: institution.institution_id,
                userId,
                accounts,
            }
            const response = await callableExchangePublicToken(data)
            debugPrint(`exchangeToken succeeded with response: ${JSON.stringify(response)}`, 'info')
        } catch (error) {
            debugPrint(`exchangeToken failed with error: ${JSON.stringify(error)}`, 'error')
        }
    } else
        throw new Error("Firebase instance or Firebase Functions NOT found.")
}



export interface CreateTransferData {
    accessToken: string
    amount: string
    description: string
    idempotencyKey: string
    email?: string
    legalName: string
    orderId: string
    plaidAccountId: string
    uid: string
}

async function createTransfer({
    accessToken,
    amount,
    description,
    idempotencyKey,
    email,
    legalName,
    orderId,
    plaidAccountId,
    uid,
}: CreateTransferData) {
    const app = getFirebase()
    if (app) {
        const functions = getFunctions(app)
        if (window.location.hostname === 'localhost')
            connectFunctionsEmulator(functions, 'localhost', 5001)
        const callableCreateTransfer = httpsCallable(functions, 'createTransfer')
        const data = {
            accessToken,
            amount,
            description,
            email,
            legalName,
            orderId,
            plaidAccountId,
            idempotencyKey,
        }

        try {
            const response = await callableCreateTransfer(data)
            debugPrint(`createTransfer succeeded for user ${uid} with response: ${JSON.stringify(response)}`, 'info')
        } catch (error) {
            debugPrint(`createTransfer failed for user ${uid} with error ${JSON.stringify(error)}`, 'error')
            throw error
        }
    } else
        throw new Error("Firebase instance or Firebase Functions NOT found.")
}

async function deleteTransfer(userId: string) {
    throw new Error("NOT IMPLEMENTED")
}


export {
    checkInUserAndSetupAccount,
    createTransfer,
    createOrder,
    deleteTransfer,
    exchangeToken,
    getFirebase,
    getLinkToken,
    observeUserData,
    removeAccount,
    setDefaultAccount,
    setEmail,
    setLegalNameAndEmail,
    updateItemState,
}