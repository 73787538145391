import React, { useEffect, useState } from 'react'
import { PhoneInput } from 'react-international-phone'
import { PhoneAuthProvider, RecaptchaVerifier, getAuth, signInWithCredential, signInWithPhoneNumber } from '@firebase/auth'
import 'react-international-phone/style.css'
import { useNavigate } from 'react-router-dom'
import goat from './assets/images/GOAT3000.webp'
import { checkInUserAndSetupAccount, setEmail } from './services/firebase'
import { debugPrint } from './utils/helpers'

declare global {
    interface Window {
        recaptchaVerifier: any
    }
}


function parsePhoneNumberToE164(phoneNumber: string): string | undefined {
    // Remove all non-digit characters
    const digitsOnly = phoneNumber.replace(/\D/g, '')

    // Check if the number is valid (10 or 11 digits)
    if (digitsOnly.length === 10) {
        // Add country code if it's missing
        return `+1${digitsOnly}`
    } else if (digitsOnly.length === 11 && digitsOnly.startsWith('1')) {
        // If it already has the country code, just add the +
        return `+${digitsOnly}`
    } else {
        return
    }
}

const Login: React.FC = () => {
    const phone = localStorage.getItem('phone')
    let validPhoneNumber: string | undefined
    if (phone)
        validPhoneNumber = parsePhoneNumberToE164(phone)

    const [phoneNumber, setPhoneNumber] = useState(validPhoneNumber ?? '')
    const [otp, setOtp] = useState('')
    const [verificationId, setVerificationId] = useState('')
    const [error, setError] = useState('')

    const navigate = useNavigate()
    const auth = getAuth()
    auth.languageCode = 'en'

    useEffect(() => {
        const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            size: 'invisible',
            callback: (response: any) => { },
            'expired-callback': () => {
                setError('Verification code expired')
                setOtp('')
                setVerificationId('')
            }
        })

        window.recaptchaVerifier = recaptchaVerifier
    }, [])

    const parseAuthError = (error: any) => {
        if ('code' in error)
            switch (error.code) {
                case 'auth/invalid-phone-number':
                    setError('Invalid phone number')
                    break
                case 'auth/missing-phone-number':
                    setError('Missing phone number')
                    break
                case 'auth/missing-verification-code':
                    setError('Missing verification code')
                    break
                case 'auth/invalid-verification-code':
                    setError('Invalid verification code')
                    break
                default:
                    setError(error.message)
                    break
            }
        else
            setError(error.message)
    }


    const requestOtp = async (e: React.FormEvent) => {
        e.preventDefault()

        if (validPhoneNumber)
            localStorage.removeItem('phone')

        const appVerifier = window.recaptchaVerifier
        setError('') // Clear previous errors

        try {
            const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            setVerificationId(confirmationResult.verificationId)
        } catch (error: any) {
            parseAuthError(error)
        }
    }

    const verifyOtp = async (e: React.FormEvent) => {
        e.preventDefault()

        setError('') // Clear previous errors

        if (verificationId) {
            const credential = PhoneAuthProvider.credential(verificationId, otp)

            try {
                const userCreds = await signInWithCredential(auth, credential)
                const email = localStorage.getItem('ue')
                // checkin
                if (email) {
                    await setEmail(email)
                    localStorage.removeItem('ue')
                }
                checkInUserAndSetupAccount({
                    uid: userCreds.user.uid,
                    email: email ?? undefined
                })
                navigate('/')
            } catch (error: any) {
                parseAuthError(error)
            }
        } else {
            debugPrint('Verification ID not found', 'error')
        }
    }

    const phoneNumberChangeHandler = (phone: string) => {
        setPhoneNumber(phone)
        if (validPhoneNumber)
            localStorage.removeItem('phone')
    }

    return (
        <main className="container-md mt-5 p-4 rounded">
            <div className="text-center mb-4">
                <img src={goat} alt="Parlay P Sports Newsletter" className="mx-auto my-5" style={{ maxHeight: "90px" }} />
            </div>
            <section className="text-center mb-4">
            </section>
            <section className='text-center'>
                {!verificationId
                    ? <form id="phone-auth-form" onSubmit={requestOtp} className='mx-auto bg-dark text-white rounded p-5' style={{ maxWidth: '400px' }}>
                        <p className='lead mb-5'>Sign in with your phone number to <strong>pay by bank transfer—the safest and most secure way</strong> to pay.</p>
                        <div className='mb-3'><PhoneInput hideDropdown={true} className='form-control' defaultCountry="us" value={phoneNumber} onChange={phoneNumberChangeHandler} /></div>
                        <button id='sign-in-button' className='btn btn-primary btn-lg btn-pill w-100' type="submit">Send Code</button>
                    </form>
                    : <form id="otp-auth-form" onSubmit={verifyOtp} className='mx-auto bg-dark text-white rounded p-5' style={{ maxWidth: '400px' }}>
                        <p className='lead mb-5'>A verification code has been texted to you. Enter it below to continue.</p>
                        <div className='mb-3'><input type="text" id='otp-input' className='form-control p-2' placeholder="One Time Password" value={otp} onChange={(e) => setOtp(e.target.value)} /></div>
                        <button id='sign-in-button' type="submit" className='btn btn-primary btn-lg btn-pill w-100'>Verify OTP</button>
                    </form>
                }
            </section>
            {error && <div className='alert alert-danger text-center mx-auto' style={{ maxWidth: '400px' }}>{error}</div>}
            <div id='recaptcha-container'></div>
        </main>
    )
}

export default Login