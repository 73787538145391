import { useState, useEffect } from 'react'
import LaunchLink from './components/LaunchLink'


// Component rendered when user is redirected back to site from Oauth institution site.
// It initiates link immediately with the original link token that was set in local storage
// from the initial link initialization.
const OAuthLink = () => {
    const [token, setToken] = useState<string | null>(null)
    const [userId, setUserId] = useState<string>('')
    const [itemId, setItemId] = useState<number>()

    const oauthObject = localStorage.getItem('oauthConfig')

    useEffect(() => {
        if (oauthObject !== null) {
            setUserId(JSON.parse(oauthObject).userId)
            setItemId(JSON.parse(oauthObject).itemId)
            setToken(JSON.parse(oauthObject).token)
        }
    }, [oauthObject])

    return (
        <>
            {token !== null && (
                <LaunchLink isOauth={true} userId={userId} itemId={itemId} token={token} />
            )}
        </>
    )
}

export default OAuthLink
