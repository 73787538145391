import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Product } from "./types"
import LoadingPlaceholder from "./components/LoadingPlaceholder"
import useAuthListener from "./utils/authListener"
import LaunchLink from "./components/LaunchLink"
import useOrders from "./services/orders"
import ProductView from "./components/ProductView"
import Footer from "./Footer"
import LegalNameAndEmailForm from "./components/LegalNameForm"
import { useLink } from "./services"
import { debugPrint } from "./utils/helpers"
import { FaRegCircleXmark } from "react-icons/fa6"
import { useUserData } from "./services/userData"
import { ReactComponent as ConnectedIcon } from "./assets/images/connected.svg"
import { ReactComponent as BoxIcon } from "./assets/images/box.svg"
import { ReactComponent as InfoIcon } from "./assets/images/info.gold.svg"
import { ReactComponent as CheckmarkIcon } from "./assets/images/checkmark.svg"
import { ReactComponent as ExclamationIcon } from "./assets/images/exclamation.svg"
import { ReactComponent as HourglassIcon } from "./assets/images/hourglass.circle.svg"
import { ReactComponent as UturnIcon } from "./assets/images/uturn.circle.svg"


/// When changing prices make sure to update the Firebase Function that creates
/// transfers. This function sets the transfer metadata 'plan' attribute based
/// on the price.
const PRODUCTS: Product[] = [
    { id: 1, name: 'Triumph Monthly', price: 1000 },
    { id: 2, name: 'Standard Monthly', price: 500 },
    { id: 3, name: 'Triumph Weekly', price: 300 },
    { id: 4, name: 'Standard Weekly', price: 150 },
]

const Dashboard: React.FC = () => {
    const navigate = useNavigate()
    const { uid } = useAuthListener()
    const {
        fetchUserData,
        setDefaultAccount,
        removeAccount,
        userData,
    } = useUserData()
    const { accounts, defaultAccount, email, hasLoaded, legalName, orders, } = userData
    const [localError, setLocalError] = useState<string | null>(null)
    const [selectedProduct, setSelectedProduct] = useState<Product>(PRODUCTS[0])
    const { orderErrorMessage, placeOrder } = useOrders()
    const [placingOrder, setPlacingOrder] = useState(false)
    const [linkActivity, setLinkActivity] = useState(false)

    const [token, setToken] = useState('')
    const { linkTokens, generateLinkToken } = useLink()

    const initiateLink = async () => {
        setLinkActivity(true)
        await generateLinkToken(uid, null)
        setLinkActivity(false)
    }

    const orderButtonHandler = async () => {
        try {
            setPlacingOrder(true)
            const orderId = await placeOrder({ name: selectedProduct.name, amount: selectedProduct.price })
            if (orderId)
                navigate(`/orders/${orderId}`)
        } catch (error) {
            debugPrint(JSON.stringify(error), 'error')
            setLocalError("An internal server error has occurred. Please contact support if the issue persists.")
        }
        setPlacingOrder(false)
    }

    useEffect(() => {
        fetchUserData(uid)
    }, [uid])

    useEffect(() => {
        setToken(linkTokens.byUser[uid])
    }, [linkTokens, uid])


    // checks local storage for 'plan'.
    // Removes the saved value after it is used.
    useEffect(() => {
        const plan = localStorage.getItem('plan')
        if (plan) {
            switch (plan) {
                case 'Triumph 1 Month - $1000':
                    setSelectedProduct(PRODUCTS[0])
                    break
                case 'Standard 1 Month - $500':
                    setSelectedProduct(PRODUCTS[1])
                    break
                case 'Triumph 1 Week - $300':
                    setSelectedProduct(PRODUCTS[2])
                    break
                case 'Standard 1 Week - $150':
                    setSelectedProduct(PRODUCTS[3])
                    break
                default:
                    setSelectedProduct(PRODUCTS[0])
                    debugPrint(`🙋‍♂️ Unhandled plan: ${plan}`, 'warn')
                    break
            }
            localStorage.removeItem('plan')
        }
    }, [])

    const navigateToOrder = (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const orderId = ev.currentTarget.getAttribute('data-order-id')
        navigate(`/orders/${orderId}`)
    }

    const ordersView = (): JSX.Element => {
        //@ts-ignore
        const ordersList = Object.keys(orders).map((key) => orders[key])
        return <tbody>
            {ordersList.sort((a, b) => b.timestamp - a.timestamp).map((order) => (
                <tr key={order.id} data-order-id={order.id} onClick={navigateToOrder} role="button">
                    <td>{prettyDate(order.timestamp)}</td>
                    <td>{order.plan}</td>
                    <td style={{ textTransform: 'capitalize' }}>{parseIcon(order.status)} {order.status}</td>
                </tr>
            ))}
        </tbody>
    }

    const prettyDate = (timestamp: number) => new Date(timestamp).toLocaleDateString()

    const productsView = (): JSX.Element => {
        return <div className="row">
            {PRODUCTS.map((product) => <ProductView
                key={product.id}
                isSelected={product.id === selectedProduct.id}
                product={product}
                selectHandler={setSelectedProduct}
            />)}
        </div>
    }

    const accountsView = (): JSX.Element => {
        const listItems = Object.keys(accounts)
            //@ts-ignore
            .map((key) => accounts[key])
            .map((account) => {
                const isDefault = (account.accountId === defaultAccount)
                const setDefault = () => setDefaultAccount(account.accountId)
                const removeItem = () => {
                    if (window.confirm(`Are you sure you want to disconnect your account ending in ${account.mask}?`))
                        removeAccount(account.accountId)
                }
                return <li key={account.accountId} className="list-group-item">
                    <div className="d-flex">
                        <p className="my-0" style={{ width: "90px" }}>**** {account.mask}</p>
                        <p className="my-0 text-muted">{account.bankName}</p>
                        <div style={{ width: "120px" }} className="ms-auto text-end">
                            {(isDefault)
                                ? <p className="text-subtle my-0" style={{ fontFamily: 'Bebas', fontSize: '18px' }}>DEFAULT</p>
                                : <button className="btn btn-text btn-sm my-0 mx-2 p-0 " onClick={setDefault}>Set default </button>
                            }
                            {(!isDefault) && <button className="btn btn-text btn-danger mx-0 p-0" onClick={removeItem}><FaRegCircleXmark /></button>}
                        </div>
                    </div>
                </li>
            })
        return <ul className="list-group mb-3"> {listItems} </ul>
    }

    const hasAccounts = Object.keys(accounts).length > 0

    // ORDER STATUS ICON (see: https://docs.google.com/spreadsheets/d/1QPr1g9VLqQoL7XtwXFy_4xIkG6dg3XYD8LIIwowABak/edit?gid=1085922130#gid=1085922130)
    function parseIcon(status: string): JSX.Element | undefined {
        switch (status) {
            case 'processing':
            case 'refund-processing':
                return <HourglassIcon className="me-1" />
            case 'paid':
            case 'refunded':
                return <CheckmarkIcon className="me-1" />
            case 'cancelled':
            case 'refund-cancelled':
                return <UturnIcon className="me-1" />
            case 'failed':
            case 'refund-failed':
                return <ExclamationIcon className="me-1" />
            default:
                return undefined
        }
    }

    if (!hasLoaded) return <LoadingPlaceholder />

    const errorMessage = orderErrorMessage ?? ""

    return (
        <div id="dashboard" className="d-flex flex-column" style={{ minHeight: '100vh' }}>
            <div className="container my-4 flex-grow-1 flex-column">
                {!!(errorMessage) && <div className='alert alert-danger text-center mx-auto'>{errorMessage}</div>}

                {/* Products Grid */}
                <section className="mb-5">
                    <div className="row">
                        {/* Connected Bank Accounts */}
                        {(!legalName || !email)
                            ? <LegalNameAndEmailForm requireEmail={!email} />
                            : <div className="col-md-4 d-flex flex-column">
                                <div>
                                    <h3 className="mb-0"><ConnectedIcon /> Connected accounts</h3>
                                    {(Object.keys(accounts).length > 0)
                                        ? <p>→ Manage your bank accounts</p>
                                        : <p><span className="badge rounded-pill highlight"><strong>STEP 1</strong></span> Set up your account</p>
                                    }
                                    {hasAccounts
                                        ? accountsView()
                                        : <p>Click the button below to connect your first account.</p>
                                    }
                                    {!!token &&
                                        <LaunchLink token={token} userId={uid} itemId={null} />
                                    }
                                </div>
                                <button className={(Object.keys(accounts).length > 0) ? "btn btn-outline-secondary my-2" : "btn btn-primary my-2"} onClick={initiateLink} disabled={linkActivity}>{
                                    linkActivity
                                        ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        : (Object.keys(accounts).length === 0) ? "Connect Bank Account" : "Add Another Account"
                                }</button>
                                <p className="mb-5"><InfoIcon /> We do not store or have access to your bank account details, by law.</p>
                            </div>
                        }
                        <div className="col-md-8 d-flex flex-column">
                            <div>
                                <h3 className="mb-0"><BoxIcon /> Products</h3>
                                {(Object.keys(accounts).length > 0)
                                    ? <p>→ Select your plan and tap 'Review & Pay' to complete your purchase</p>
                                    : <p><span className="badge rounded-pill highlight"><strong>STEP 2</strong></span> Select your plan and tap 'Review & Pay' to complete your purchase</p>
                                }
                                {productsView()}
                            </div>
                            <button className="btn btn-primary w-100 my-2" onClick={orderButtonHandler} disabled={!Object.keys(accounts).length || !hasLoaded}>
                                {placingOrder
                                    ? (<> <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Processing... </>)
                                    : ('Review & Pay')
                                }
                            </button>
                            <p className="mb-5"><InfoIcon /> All payments are processed by The Sports Newsletter, LLC - Parlay P's parent company.</p>
                        </div>

                    </div>
                </section>

                {(Object.keys(orders).length > 0) &&
                    <section className="d-flex flex-grow-1 flex-column">
                        <h3><BoxIcon /> Your Orders</h3>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Plan</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            {ordersView()}
                        </table>
                    </section>
                }

            </div>
            <Footer />
        </div>
    )
}

export default Dashboard
