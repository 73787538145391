import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import useAuthListener from "./utils/authListener";
import LoadingPlaceholder from "./components/LoadingPlaceholder";
import { OrderType, TransferType } from "./types";
import useTransfers from "./services/transfers";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Receipt } from "./components/Receipt";
import { debugPrint } from "./utils/helpers";
import Footer from "./Footer";
import { useUserData } from "./services/userData";
import { ReactComponent as CheckmarkIcon } from "./assets/images/checkmark.svg"
import { ReactComponent as ExclamationIcon } from "./assets/images/exclamation.svg"
import { ReactComponent as HourglassIcon } from "./assets/images/hourglass.circle.svg"
import { ReactComponent as UturnIcon } from "./assets/images/uturn.circle.svg"
import { ReactComponent as TrophyIcon } from "./assets/images/trophy.svg"
import { ReactComponent as MedalIcon } from "./assets/images/medal.svg"
import { ReactComponent as InfoIcon } from "./assets/images/info.gold.svg"

const Order = (): JSX.Element => {
    const params = useParams()
    const { uid, phoneNumber } = useAuthListener()
    const { userData, fetchUserData } = useUserData()
    const { transferErrorMessage, inProgress, createTransfer } = useTransfers()
    const { accounts, defaultAccount, email, legalName, orders } = userData

    const [order, setOrder] = useState<OrderType | undefined>()
    const [transfers, setTransfers] = useState<TransferType[]>([])
    const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>()

    const lastPartOf = (uuid: string) => uuid.split('-').pop()

    const prettyDate = (timestamp: number) => new Date(timestamp).toLocaleDateString()

    const handlePayNow = async () => {
        //@ts-ignore
        const account = accounts[selectedAccountId ?? ""]
        if (!order || !account || !legalName) {
            debugPrint("Can't initiate a transfer: Invalid order, account or legal name is missing", 'error')
            return
        }

        function shortnedDescription(orderPlan: string): string {
            switch (orderPlan) {
                case 'Triumph Monthly':
                    return 'Triumph Mo'
                case 'Standard Monthly':
                    return 'Standard Mo'
                case 'Triumph Weekly':
                    return 'Triumph Wk'
                case 'Standard Weekly':
                    return 'Standard Wk'
                default:
                    return "ParlayP Sports"
            }
        }

        const transferData = {
            accessToken: account.accessToken!,
            amount: order.amount.toFixed(2).toString(),
            description: shortnedDescription(order.plan),           // Plaid has a limitation to 15 characters for description
            email,
            legalName,
            orderId: order.id,
            plaidAccountId: account.accountId,
            uid,
        }

        await createTransfer(transferData)
    }


    useEffect(() => {
        if ('orderId' in params) {
            const currentOrder = orders[params.orderId!]
            if (currentOrder) {
                setOrder(currentOrder)
                const transfers = Object.keys(currentOrder.transfers ?? {}).map((key) => currentOrder.transfers![key]).sort((a, b) => b.createdAt - a.createdAt)
                setTransfers(transfers)
            } else {
                debugPrint(`Order not found: ${params.orderId}`, 'error')
            }
        }

        if (!selectedAccountId)
            if (!!defaultAccount)
                setSelectedAccountId(defaultAccount)
            else
                setSelectedAccountId(Object.keys(accounts)[0])
    }, [accounts, defaultAccount, orders])


    useEffect(() => {
        if (!Object.keys(orders).length)
            fetchUserData(uid)
    }, [uid])


    // @ts-ignore
    const accountsSelectOptions = Object.keys(accounts).map((key) => accounts[key]).map((account) => (
        <option key={account.accountId} value={account.accountId}> [**** {account.mask}] {account.bankName} </option>
    ))

    const transfersList = transfers.map((transfer) => {
        const status = (transfer.status === 'failed')
            ? transfer.rationale ?? transfer.status
            : transfer.status
        return <tr key={transfer.id}>
            <td>{prettyDate(transfer.createdAt)}</td>
            <td>{transfer.description}</td>
            <td>${transfer.amount.toFixed(2)}</td>
            <td style={{ textTransform: 'capitalize' }}>{status.replace(/[-.]/g, " ")}</td>
        </tr>
    })

    const selectChangeHandler = (ev: React.ChangeEvent<HTMLSelectElement>) => setSelectedAccountId(ev.target.value)

    const settledOrPendingTransferExists = (transfers.find((transfer) => transfer.status === 'settled' || transfer.status === 'pending') !== undefined)
    const isPayable = (
        !!selectedAccountId &&
        !!legalName &&
        order?.status !== 'paid' &&
        !settledOrPendingTransferExists
    )


    const invoiceButton = (): JSX.Element => {
        const paymentTransfer = transfers.find((transfer) => transfer.status === 'settled')

        if (order?.status !== 'paid' || !paymentTransfer || !legalName)
            return <></>

        const receipt = <Receipt
            amount={order.amount}
            legalName={legalName}
            phoneNumber={phoneNumber}
            product={order.plan}
            transferId={paymentTransfer.id}
            transferTimestamp={paymentTransfer.updatedAt ?? paymentTransfer.createdAt}
            orderId={order.id}
        />

        return <div className="d-flex justify-content-center">
            <PDFDownloadLink className="btn btn-primary px-5" document={receipt} fileName={`PPSN-${lastPartOf(order.id)}.pdf`}>
                {({ blob, url, loading, error }) =>
                    loading ? 'Processing...' : 'Download receipt'
                }
            </PDFDownloadLink>
        </div>
    }


    /// USER MESSAGE (see: https://docs.google.com/spreadsheets/d/1QPr1g9VLqQoL7XtwXFy_4xIkG6dg3XYD8LIIwowABak/edit?gid=1085922130#gid=1085922130)
    function parseUserMessage(status: string): string | undefined {
        switch (status) {
            case 'processing':
                return "Your payment is being processed.\nYour Parlay P account should be active."
            case 'paid':
                return "Your payment was successful!\nThank you for your purchase."
            case 'cancelled':
                return "Your payment was cancelled."
            case 'failed':
                return "Your payment could not be processed.\nPlease contact support for assistance."
            case 'refund-processing':
                return "Your refund is being processed."
            case 'refunded':
                return "Your refund was completed successfully.\nThe funds should appear in your account shortly."
            case 'refund-cancelled':
                return "Your refund was cancelled.\nIf you need help, contact support."
            case 'refund-failed':
                return "Your refund could not be processed.\nPlease contact support for assistance."
            default:
                return undefined
        }
    }

    const userMessage = parseUserMessage(order?.status ?? "n/a")

    // ORDER STATUS ICON (see: https://docs.google.com/spreadsheets/d/1QPr1g9VLqQoL7XtwXFy_4xIkG6dg3XYD8LIIwowABak/edit?gid=1085922130#gid=1085922130)
    function parseIcon(status: string): JSX.Element | undefined {
        switch (status) {
            case 'processing':
            case 'refund-processing':
                return <HourglassIcon className="me-1" />
            case 'paid':
            case 'refunded':
                return <CheckmarkIcon className="me-1" />
            case 'cancelled':
            case 'refund-cancelled':
                return <UturnIcon className="me-1" />
            case 'failed':
            case 'refund-failed':
                return <ExclamationIcon className="me-1" />
            default:
                return undefined
        }
    }

    const icon = parseIcon(order?.status ?? "n/a")

    if (Object.keys(orders).length === 0) return <LoadingPlaceholder />

    if (!order) return <h3>Order ID is invalid</h3>

    return <div id="order" className="d-flex flex-column" style={{ minHeight: '100vh' }}>
        <div className="container d-flex flex-grow-1 flex-column" style={{ maxWidth: '600px' }}>
            <div className="my-2">
                <a href="/" className="fs-4 text-white text-decoration-none fw-bold">← Dashboard</a>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between mb-2">
                        <h3 className="mb-3 d-inline">Order: #{lastPartOf(params.orderId ?? '')}</h3>
                        {order.status !== "new" &&
                            <h4 className="mb-3 d-inline">{icon} Status: {order.status.replace("-", " ").toUpperCase()}</h4>
                        }
                    </div>
                    {userMessage && <div className="alert alert-secondary text-center mx-auto mb-3" style={{ borderWidth: 2, background: 'none', whiteSpace: 'pre-line' }}>{userMessage}</div>}
                    <div className="text-center mt-5">
                        {order.plan.includes("Standard") && <MedalIcon />}
                        {order.plan.includes("Triumph") && <TrophyIcon />}
                        <h4 className="card-title mt-3">{order.plan}</h4>
                        {isPayable
                            ? <h5 className="mb-4" style={{ color: "#cd201f" }}>${order.amount.toFixed(2)}</h5>
                            : <h5 className="mb-4">${order.amount.toFixed(2)}</h5>
                        }
                        {isPayable &&
                            <div className="mb-4">
                                <select id="accountSelect" className="form-select mb-2" value={selectedAccountId} onChange={selectChangeHandler} >
                                    {accountsSelectOptions}
                                </select>
                                <button className="btn btn-primary w-100 my-3" onClick={handlePayNow} disabled={inProgress} >{
                                    inProgress
                                        ? <><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>'Processing...'</>
                                        : 'Pay Now'
                                }</button>
                                <div className="text-muted fs-6" style={{ textAlign: 'justify' }}>
                                    <p><InfoIcon /> Payment for this order is a one-time ACH transfer. By pressing the 'PAY NOW' button you authorize The Sports Newsletter, LLC to process the payment.<br /><br />After you authorize this payment, please contact support if you need to cancel the transfer.<br /><br />All payments are processed by The Sports Newsletter, LLC - Parlay P's parent company.</p>
                                </div>
                            </div>
                        }
                    </div>
                    {invoiceButton()}
                    <hr className="my-4" />
                    {transfers.length > 0 &&
                        <div>
                            <h3>Transfers</h3>
                            {(transferErrorMessage) &&
                                <div className='alert alert-danger mx-auto mb-3'>
                                    <ExclamationIcon className="me-1" /><p className="text-danger d-inline my-2"><strong>Error</strong></p>
                                    <p>{transferErrorMessage}</p>
                                </div>
                            }
                            {(transfers[0].status === 'pending') &&
                                <div className="alert alert-success mb-3">
                                    <div className="d-flex align-items-center">
                                        <CheckmarkIcon className="me-1" /><p className="text-success d-inline my-2"> <strong> The bank transfer has been initiated.</strong></p>
                                    </div>
                                    <p className="mb-0">Your account will be automatically upgraded shortly. When your premium account is activated you will be notified in the app and will have access to premium picks.</p>
                                </div>
                            }
                            <table className="table">
                                <tbody>
                                    {transfersList}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </div>
        <Footer />
    </div>
}

export default Order