import React, {
    useCallback,
    useContext,
    useMemo,
    useReducer,
    Dispatch,
    createContext,
} from 'react'

import { createOrder } from './firebase'
import { debugPrint } from '../utils/helpers'
import { FirebaseError } from 'firebase/app'

interface OrdersState {
    inProgress: boolean
    error?: Error
    orderErrorMessage?: string
}

const initialState: OrdersState = {
    inProgress: false,
}

type OrdersAction =
    | { type: 'ORDER_STARTED' }
    | { type: 'ORDER_PLACED' }
    | { type: 'ORDER_FAILED'; payload: Error }


interface OrdersContextShape extends OrdersState {
    placeOrder: (props: { name: string, amount: number, }) => Promise<string>
    dispatch: Dispatch<OrdersAction>
}

const OrdersContext = createContext<OrdersContextShape>(
    initialState as OrdersContextShape
)

/**
 * @desc Maintains the Orders context state.
 */
export function OrdersProvider(props: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(reducer, initialState)

    const placeOrder = useCallback(async ({ name, amount }: {
        amount: string,
        name: string,
    }) => {
        try {
            dispatch({ type: 'ORDER_STARTED' })
            const orderId = await createOrder({ name, amount })
            dispatch({ type: 'ORDER_PLACED' })
            return orderId
        } catch (error) {
            if (error instanceof FirebaseError) {
                debugPrint(`createOrder failed with FirebaseError: ${JSON.stringify(error)}`, 'error')
                dispatch({ type: 'ORDER_FAILED', payload: error as FirebaseError })
            } else {
                debugPrint(`createOrder failed with error: ${JSON.stringify(error)}`, 'error')
                dispatch({ type: 'ORDER_FAILED', payload: error as Error })
            }
        }
    }, [])

    const value = useMemo(
        () => ({
            ...state,
            placeOrder,
            dispatch,
        }),
        [state, placeOrder]
    )

    return <OrdersContext.Provider value={value} {...props} />
}

/**
 * @desc Handles updates to the Transfer state as dictated by dispatched actions.
 */
function reducer(state: any, action: OrdersAction) {
    switch (action.type) {
        case 'ORDER_STARTED':
            return {
                ...state,
                inProgress: true,
                error: {},
                orderErrorMessage: undefined,
            }
        case 'ORDER_PLACED':
            return {
                ...state,
                inProgress: false,
                error: {},
                orderErrorMessage: undefined,
            }
        case 'ORDER_FAILED':
            return {
                ...state,
                inProgress: false,
                error: action.payload,
                orderErrorMessage: "Failed to create the order. This is an internal error. Please contact support if the issue persists.",
            }
        default:
            debugPrint(`Unknown action: ${JSON.stringify(action)}`, 'warn')
            return state
    }
}

/**
 * @desc A convenience hook to provide access to the Transfer context state in components.
 */
export default function useOrders() {
    const context = useContext(OrdersContext)
    if (!context)
        throw new Error(`useOrders must be used within a OrdersProvider`)

    return context
}
