import React, { useEffect } from 'react'
import {
  usePlaidLink,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkError,
  PlaidLinkOptionsWithLinkToken,
  PlaidLinkOnEventMetadata,
  PlaidLinkStableEvent,
} from 'react-plaid-link'

import { debugPrint } from '../utils/helpers'
import { useLink, useErrors } from '../services'
import { exchangeToken, updateItemState } from '../services/firebase'

interface Props {
  isOauth?: boolean
  token: string
  userId: string
  itemId?: number | null
  children?: React.ReactNode
}

export default function LaunchLink(props: Props) {
  const { generateLinkToken, deleteLinkToken } = useLink()
  const { setError, resetError } = useErrors()

  // define onSuccess, onExit and onEvent functions as configs for Plaid Link creation
  const onSuccess = async (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    debugPrint("[LaunchLink onSuccess] :: metadata: " + JSON.stringify(metadata) + "userID: " + props.userId + "itemID: " + props.itemId)
    if (props.itemId != null) {           // update mode: no need to exchange public token
      await updateItemState(props.itemId, 'good')
      deleteLinkToken(null, props.itemId)
    } else {                              // regular link mode: exchange public token for access token
      await exchangeToken(
        publicToken,
        metadata.institution,
        metadata.accounts,
        props.userId
      )
    }
    resetError()
    deleteLinkToken(props.userId, null)
  }

  const onExit = async (
    error: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata
  ) => {
    debugPrint("[LaunchLink onExit] :: error: " + JSON.stringify(error) + "metadata: " + JSON.stringify(metadata) + "userID: " + props.userId, 'error')
    if (error !== null && error.error_code === 'INVALID_LINK_TOKEN')
      await generateLinkToken(props.userId, props.itemId)

    if (error != null)
      setError(error.error_code, error.display_message || error.error_message)
    // to handle other error codes, see https://plaid.com/docs/errors/
  }

  const onEvent = async (
    eventName: PlaidLinkStableEvent | string,
    metadata: PlaidLinkOnEventMetadata
  ) => {
    // handle errors in the event end-user does not exit with onExit function error enabled.
    if (eventName === 'ERROR' && metadata.error_code != null)
      setError(metadata.error_code, ' ')

    debugPrint(`LaunchLink onEvent succeeded with eventName: ${eventName} and metadata: ${JSON.stringify(metadata)}`, 'info')
  }

  const config: PlaidLinkOptionsWithLinkToken = {
    onSuccess,
    onExit,
    onEvent,
    token: props.token,
  }

  if (props.isOauth) {
    config.receivedRedirectUri = window.location.href // add additional receivedRedirectUri config when handling an OAuth reidrect
    debugPrint(`LaunchLink receivedRedirectUri: ${config.receivedRedirectUri}`, 'info')
  }

  const { open, ready } = usePlaidLink(config)

  useEffect(() => {
    if (props.isOauth && ready) {
      open()
    } else if (ready) {
      // set link token, userId and itemId in local storage for use if needed later by OAuth
      localStorage.setItem(
        'oauthConfig',
        JSON.stringify({
          userId: props.userId,
          itemId: props.itemId,
          token: props.token,
        })
      )
      open()
    }
  }, [ready, open, props.isOauth, props.userId, props.itemId, props.token])

  return <></>
}
